import { CSSProperties } from 'react';

import { createTheme } from '@mui/material/styles';

import variables from '../styles/variables.module.scss';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    headline: CSSProperties;
    supportingText: CSSProperties;
    error: CSSProperties;
    overline: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headline?: CSSProperties;
    supportingText: CSSProperties;
    overline: CSSProperties;
    error: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headline: true;
    supportingText: true;
    overline: true;
    error: true;
  }
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#006B5D',
    },
    secondary: {
      main: '#CDE8E1',
    },
    background: {
      default: variables.background_dark_1,
      paper: variables.background_light_1,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '28px',
          padding: '24px',
        },
      },
    },
  },
  typography: {
    fontFamily: 'inherit',
    h6: {
      fontSize: 22,
      fontWeight: 400,
      lineHeight: '28px',
    },
    button: {
      textTransform: 'none',
    },
    headline: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: '#191C1B',
    },
    supportingText: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#3F4946',
    },
    overline: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#3F4946',
    },
    error: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: '#BA1A1A',
    },
  },
});
