import { DocumentType } from '@/constants/common';

interface IosMessageHandlers {
  documentTypeSelectorHandler: {
    postMessage: (message: { documentType: DocumentType | undefined; identId: string }) => void;
  };
  processHandler: {
    postMessage: (message: { action: string }) => void;
  };
  authHandler: {
    postMessage: (message: { readonly email: string; readonly requestUri: string }) => void;
  };
}

declare global {
  interface Window {
    webkit: {
      messageHandlers: IosMessageHandlers;
    };
  }
}
export const iOsDocumentTypeSelectorHandler = (documentType: DocumentType | undefined, identId: string): void => {
  const { documentTypeSelectorHandler } = window.webkit?.messageHandlers || {};
  if (documentTypeSelectorHandler) {
    documentTypeSelectorHandler.postMessage({
      documentType,
      identId,
    });
  }
};
export const iOsProcessCancelHandler = (): void => {
  const { processHandler } = window.webkit?.messageHandlers || {};
  if (processHandler) {
    processHandler.postMessage({
      action: 'cancel',
    });
  }
};

export const iOsSendAuthMeta = (payload: { email: string; requestUri: string; lang: string }): void => {
  const { authHandler } = window.webkit?.messageHandlers ?? {};

  if (authHandler) {
    authHandler.postMessage(payload);
  } else {
    console.warn('iOsSendAuthMeta: authHandler not found');
  }
};
