import { QueryClient } from '@tanstack/react-query';

import { verifyApi } from '@/api/verify';

import { VerificationDocumentsDataResponse } from '@/types/responses';

import { QUERIES } from '../queries';

export const verifyFetchQueries = {
  useFetchRetrieveRedirectUrl: async (
    params: { processId: string; subProcessId: string },
    queryClient: QueryClient,
  ) => {
    const request = await queryClient.fetchQuery({
      queryKey: [QUERIES.VERIFY_REDIRECT_URL, params],
      queryFn: () => verifyApi.retrieveRedirectUrl(params),
    });
    return request;
  },
  useFetchUploadedDocumentsData: async (
    params: { processId: string; subProcessId: string },
    queryClient: QueryClient,
  ) => {
    const request = await queryClient.fetchQuery<unknown, unknown, VerificationDocumentsDataResponse>({
      queryKey: [QUERIES.UPLOADED_DOCUMENTS_DATA, params],
      queryFn: () => verifyApi.getUploadedDocumentsData(params),
    });
    return request;
  },
};
