import { request } from '@/api/axios';
import { InternalEndpoints, MasterDataEndpoints, PosEndpoints } from '@/api/endpoints';

import { Country, PosScanEventPayload } from '@/types/interfaces';
import {
  InternalPartnerLogoResponse,
  MasterDataServiceServiceProviderDataResponse,
  PosRegisterOptionResponse,
  PosServiceProviderIdResponse,
  PosStatusesResponse,
} from '@/types/responses';
import { QueryParamsWithProcessAndSubProcessId, RequestWithProcessId } from '@/types/types';

import { generatePath } from '@/utils/generatePath';

export const commonApi = {
  statuses: async (params: RequestWithProcessId): Promise<PosStatusesResponse> => {
    const { data } = await request.get(generatePath<RequestWithProcessId>(PosEndpoints.Statuses, params));
    return data;
  },
  countries: async (): Promise<Country[]> => {
    const { data } = await request.get(generatePath(MasterDataEndpoints.Countries));
    return data;
  },
  serviceProviderData: async (params: {
    serviceProviderId: string;
  }): Promise<MasterDataServiceServiceProviderDataResponse> => {
    const { data } = await request.get(
      generatePath<{ serviceProviderId: string }>(MasterDataEndpoints.ServiceProviderData, params),
    );
    return data;
  },
  serviceProviderId: async (params: RequestWithProcessId): Promise<PosServiceProviderIdResponse> => {
    const { data } = await request.get(generatePath<RequestWithProcessId>(PosEndpoints.ServiceProviderId, params));
    return data;
  },
  cancelProcess: async (params: QueryParamsWithProcessAndSubProcessId): Promise<PosStatusesResponse> => {
    const { data } = await request.post(
      generatePath<QueryParamsWithProcessAndSubProcessId>(PosEndpoints.CancelProcess, params),
    );
    return data;
  },
  partnerLogo: async (params: RequestWithProcessId): Promise<InternalPartnerLogoResponse> => {
    const { data } = await request.get(generatePath<RequestWithProcessId>(InternalEndpoints.PartnerLogo, params, true));
    return data;
  },
  registerOption: async (params: RequestWithProcessId): Promise<PosRegisterOptionResponse> => {
    const { data } = await request.get(generatePath<RequestWithProcessId>(PosEndpoints.RegisterOption, params));
    return data;
  },
  scanEvent: async (params: RequestWithProcessId, payload: PosScanEventPayload): Promise<object> => {
    const { data } = await request.post(generatePath<RequestWithProcessId>(PosEndpoints.ScanEvents, params), payload);
    return data;
  },
};
