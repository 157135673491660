// eslint-disable-next-line import/no-extraneous-dependencies
import { MockedRequest } from 'msw';

import { isBrowser, isDebug } from '@/utils/environment';

const onUnhandledRequest = (req: MockedRequest): void => {
  // Ignore Next.js requests
  if (req.url.pathname.startsWith('/_next') || req.url.pathname.startsWith('/__next')) {
    return;
  }

  if (req.url.searchParams.get('method')) {
    return;
  }

  console.warn(`Unhandled request: ${req.method} ${req.url.href}`);
};

export const setupMocks = async () => {
  if (isBrowser()) {
    const { worker } = await import('./worker');
    worker.start({
      onUnhandledRequest,
    });
    if (isDebug()) {
      worker.printHandlers();
    }
  } else {
    const { server } = await import('./server');
    server.listen({
      onUnhandledRequest,
    });
    if (isDebug()) {
      server.printHandlers();
    }
  }
};
