import { FC, PropsWithChildren, createContext } from 'react';

import { UseErrorDialog } from '@/hooks/useErrorDialog';

export const GlobalErrorContext = createContext<Omit<UseErrorDialog, 'setErrorDialog'> | null>(null);

export const GlobalErrorConsumer = GlobalErrorContext.Consumer;

export const GlobalErrorProvider: FC<PropsWithChildren<Omit<UseErrorDialog, 'setErrorDialog'>>> = ({
  children,
  hideChildren,
  isError,
  errorDialogParams,
}) => {
  return (
    <GlobalErrorContext.Provider value={{ errorDialogParams, hideChildren, isError }}>
      {children}
    </GlobalErrorContext.Provider>
  );
};
