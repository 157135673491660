import { useCallback, useState } from 'react';

import { ErrorDialogProps } from '@/components/dialogs/ErrorDialog';

type SetErrorDialog = (isOpen: boolean, newParams?: ErrorDialogProps, shouldHideChildren?: boolean) => void;

export type UseErrorDialog = {
  isError: boolean;
  hideChildren: boolean;
  errorDialogParams: ErrorDialogProps;
  setErrorDialog: SetErrorDialog;
};

export type UseErrorDialogParams = {
  params?: ErrorDialogProps;
  isError?: boolean | boolean[];
  hideChildren?: boolean;
};

export const useErrorDialog = ({
  params,
  isError: hasError = false,
  hideChildren: hasHideChildren = false,
}: UseErrorDialogParams = {}): UseErrorDialog => {
  const [isError, setIsError] = useState<boolean>(Array.isArray(hasError) ? hasError.some(Boolean) : hasError);
  const [hideChildren, setHideChildren] = useState<boolean>(hasHideChildren);

  const getErrorDialogParams = (config: ErrorDialogProps): ErrorDialogProps => ({
    onClose: () => setIsError(false),
    ...config,
  });

  const [errorDialogParams, setErrorDialogParams] = useState<ErrorDialogProps>(getErrorDialogParams(params ?? {}));

  const setErrorDialog: SetErrorDialog = useCallback(
    (isOpen: boolean, newParams?: ErrorDialogProps, newHideChildren?: boolean) => {
      if (newParams) {
        setErrorDialogParams(
          getErrorDialogParams({
            ...params,
            ...newParams,
            onClose: () => {
              newParams?.onClose?.();
              setIsError(false);
              setHideChildren(false);
            },
          }),
        );
      }

      if (newHideChildren !== undefined) {
        setHideChildren(newHideChildren);
      }

      setIsError(isOpen);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    isError,
    hideChildren,
    errorDialogParams,
    setErrorDialog,
  };
};
