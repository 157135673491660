import { QueryClient } from '@tanstack/react-query';

import { commonApi } from '@/api/common';

import { QUERIES } from '@/queries/queries';

export const commonFetchQueries = {
  useFetchStatuses: async (params: { processId: string }, queryClient: QueryClient) => {
    const request = await queryClient.fetchQuery({
      queryKey: [QUERIES.STATUSES_RETRIEVE, params.processId],
      queryFn: () => commonApi.statuses(params),
      staleTime: 0,
      cacheTime: 0,
    });
    return request;
  },
  useServiceProviderId: async (params: { processId: string }, queryClient: QueryClient) => {
    const request = await queryClient.fetchQuery({
      queryKey: [QUERIES.SERVICE_PROVIDER_ID_RETRIEVE, params.processId],
      queryFn: () => commonApi.serviceProviderId(params),
      staleTime: 20 * 60 * 1000,
      cacheTime: 25 * 60 * 1000,
    });
    return request;
  },
  useServiceProviderData: async (params: { serviceProviderId: string }, queryClient: QueryClient) => {
    const request = await queryClient.fetchQuery({
      queryKey: [QUERIES.SERVICE_PROVIDER_DATA_RETRIEVE, params.serviceProviderId],
      queryFn: () => commonApi.serviceProviderData(params),
      staleTime: 20 * 60 * 1000,
      cacheTime: 25 * 60 * 1000,
    });
    return request;
  },
};
