export type PathParams = Record<string, string | number> | undefined;
export type SearchParams = Record<string, string>;

//  Pos endpoints
export enum PosEndpoints {
  UserDetails = '/v1/pos/data/:processId/user',
  Statuses = '/v1/pos/data/:processId/statuses',
  AllowedDocuments = '/v1/pos/data/:processId/allowed-documents',
  ConsentLinks = '/v1/pos/data/:processId/idents/:identId/consent-links',
  LegalEntityAddress = '/v1/pos/data/:processId/documents-verifications/:subProcessId/legal-entity-address',
  LegalEntityDocumentVerification = '/v1/pos/data/:processId/documents-verifications/:subProcessId/documents',
  LegalEntityRepresentativeData = '/v1/pos/data/:processId/documents-verifications/:subProcessId/legal-entity-representatives',
  LegalEntitySelectRepresentativeAndPoA = '/v1/pos/data/:processId/documents-verifications/:subProcessId/legal-entity-representatives',
  LocalLogin = '/v1/pos/data/:processId/idents/:identId/local-login',
  OCRData = '/v1/pos/data/:processId/idents/:identId/ocr-data',
  RegisterUserDetails = '/v1/pos/data/:processId/user',
  UpdateUserPersonalDetails = '/v1/pos/data/:processId/idents/:identId',
  SignServiceProcessId = '/v1/pos/data/:processId/signs/:signId/sign-service-process-id',
  RedirectUrl = '/v1/pos/data/:processId/idents/:subProcessId/redirect-url',
  ServiceProviderId = '/v1/pos/data/:processId/service-provider-id',
  CancelProcess = '/v1/pos/data/:processId/idents/:subProcessId/cancel',
  UndoStep = '/v1/pos/data/:processId/documents-verifications/:subProcessId/undo',
  RegisterOption = '/v1/pos/data/:processId/register-option',
  ScanEvents = '/v1/pos/data/:processId/scan-events',
  VerifyCancel = '/v1/pos/data/:processId/documents-verifications/:subProcessId/cancel',
  VerifyRedirectUrl = '/v1/pos/data/:processId/documents-verifications/:subProcessId/redirect-url',
  VerifyGetUploadedDocumentsData = '/v1/pos/data/:processId/documents-verifications/:subProcessId/document-metadata',
  VerifyUploadDocumentData = '/v1/pos/data/:processId/documents-verifications/:subProcessId/documents/:documentId',
  VerifyFinishDocumentVerification = '/v1/pos/data/:processId/documents-verifications/:subProcessId/finish',
}

//  Waas endpoints
export enum WaasEndpoints {
  Par = '/dipp/api/par/local-ident',
  Auth = '/oauth2/auth',
  UserType = '/dipp/api/v3/users/type',
  CodeChallenge = '/dipp/api/v1/pos/waas-data/:processId/code-challenge',
  VerifyCode = '/dipp/api/v1/pos/waas-data/:processId/verify-code',
  Ident = '/dipp/api/v1/pos/waas-data/:processId/ident',
}

//  Query endpoints
export enum MasterDataEndpoints {
  Countries = '/query/countries',
  LegalEntities = '/v1/legal-entities/autosuggestion',
  ServiceProviderData = '/v2/service-providers/:serviceProviderId',
  CompanyData = '/v1/legal-entities/companies/:companyId',
}

export enum InternalEndpoints {
  PartnerLogo = '/api/partner-logo/:processId',
  WaasAuth = '/api/waas/auth',
  RegisterUserDetails = '/api/waas/user',
  CodeChallenge = '/api/waas/code-challenge/:processId',
  IsWaas = '/api/waas/is-waas',
}

export type Endpoints = PosEndpoints | MasterDataEndpoints | InternalEndpoints | WaasEndpoints;
