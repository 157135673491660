import { CSSProperties, forwardRef, ReactElement, ReactNode, Ref } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Breakpoint } from '@mui/system';

import { ModalType } from '@/components/dialogs/Modal/Modal.constants';

import { useTestIdGenerator } from '@/hooks/useTestIdGenerator';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<unknown, string>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ModalOptions {
  title?: ReactNode;
  maxWidth?: Breakpoint;
  paperPropsStyles?: CSSProperties;
  buttonCloseLabel?: string;
  customActions?: ReactNode | ReactNode[];
  hasTransition?: boolean;
  content?: ReactNode;
  confirmationButtonLabel?: string;
  actionButtonsAlign?: 'center' | 'flex-end';
  actionButtonsDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
}

export interface ModalProps {
  open: boolean;
  modalType?: ModalType;
  onClose?: () => void;
  onConfirm?: () => void;
  options?: ModalOptions;
}

export const Modal = (props: ModalProps) => {
  const { options = {}, open = false, onClose, onConfirm, modalType = ModalType.Informational } = props;
  const {
    maxWidth,
    paperPropsStyles,
    title,
    buttonCloseLabel,
    customActions,
    hasTransition,
    content,
    confirmationButtonLabel,
    actionButtonsDirection = 'row',
    actionButtonsAlign = 'flex-end',
  } = options;

  const generateTestId = useTestIdGenerator('modal');

  return (
    <Dialog
      PaperProps={{ sx: paperPropsStyles }}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      TransitionComponent={hasTransition ? Transition : undefined}>
      {title && (
        <DialogTitle variant="h5" data-testid={generateTestId('title')}>
          {title}
        </DialogTitle>
      )}
      <DialogContent data-testid={generateTestId('content')}>{content}</DialogContent>
      <DialogActions
        sx={{
          flexDirection: actionButtonsDirection,
          justifyContent: actionButtonsAlign,
          alignItems: actionButtonsAlign,
        }}>
        {buttonCloseLabel && (
          <Button onClick={onClose} data-testid={generateTestId('close-button')}>
            {buttonCloseLabel}
          </Button>
        )}
        {modalType === ModalType.Confirmation && (
          <Button key="confirm" color="primary" onClick={onConfirm} data-testid={generateTestId('confirm-button')}>
            {confirmationButtonLabel}
          </Button>
        )}
        {customActions}
      </DialogActions>
    </Dialog>
  );
};
