import { Endpoints, PathParams, SearchParams } from '@/api/endpoints';

import { API_PREFIX } from '@/constants/common';

import { isBrowser } from './environment';
import { generateUrlParams } from './generateUrlSearchParams';

export function replaceQueryParams<P extends PathParams>(path: string, params: P) {
  if (!params) {
    return path;
  }

  Object.keys(params).forEach((key: string): void => {
    path = params[key] ? path.replace(`:${key}`, params[key].toString()) : path;
  });

  return path;
}

export function generatePath<P extends PathParams, S extends SearchParams | undefined = undefined>(
  pathPattern: Endpoints,
  params?: P,
  internal?: boolean,
  searchParams?: S,
): string {
  const path: string = internal ? pathPattern : `${API_PREFIX}${pathPattern}`;
  let result = isBrowser() ? path : `${process.env.VERIMI_FRONTEND_URL}${path}`;

  if (params) {
    result = replaceQueryParams(result, params);
  }
  if (searchParams) {
    result += generateUrlParams(searchParams);
  }
  return result;
}
