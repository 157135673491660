import { request } from '@/api/axios';
import { PosEndpoints } from '@/api/endpoints';

import {
  PosOCRDataPayload,
  UpdatedUserDetails,
  UpdatedUserPersonalDetailsFormData,
  UserDetails,
} from '@/types/interfaces';
import {
  PosAllowedDocumentsResponse,
  PosConsentLinksResponse,
  PosIdentLocalLoginResponse,
  PosOCRDataResponse,
  PosRedirectUrlResponse,
  PosSignServiceProcessIdResponse,
} from '@/types/responses';
import { QueryParamsWithProcessAndSubProcessId, RequestWithProcessId } from '@/types/types';

import { generatePath } from '@/utils/generatePath';

export type RequestWithIdentId = RequestWithProcessId<{
  identId: string;
}>;

export type SignServiceProcessIdPathParams = RequestWithProcessId<{
  signId: string;
}>;

export const identApi = {
  consentLinks: async (params: RequestWithIdentId): Promise<PosConsentLinksResponse> => {
    const { data } = await request.get(generatePath<RequestWithIdentId>(PosEndpoints.ConsentLinks, params));
    return data;
  },
  localLogin: async (params: RequestWithIdentId): Promise<PosIdentLocalLoginResponse> => {
    const { data } = await request.get(generatePath<RequestWithIdentId>(PosEndpoints.LocalLogin, params));
    return data;
  },
  updateUserPersonalDetails: async (
    params: RequestWithIdentId,
    body: UpdatedUserPersonalDetailsFormData,
  ): Promise<unknown> => {
    const { data } = await request.put(
      generatePath<RequestWithIdentId>(PosEndpoints.UpdateUserPersonalDetails, params),
      body,
    );
    return data;
  },
  updateUserDetails: async (params: RequestWithProcessId, body: UpdatedUserDetails): Promise<UserDetails> => {
    const { data } = await request.put(
      generatePath<RequestWithProcessId>(PosEndpoints.RegisterUserDetails, params),
      body,
    );
    return data;
  },
  retrieveUserDetails: async (params: RequestWithProcessId): Promise<UserDetails> => {
    const { data } = await request.get(generatePath<RequestWithProcessId>(PosEndpoints.UserDetails, params));
    return data;
  },
  allowedDocuments: async (params: RequestWithProcessId): Promise<PosAllowedDocumentsResponse> => {
    const { data } = await request.get(generatePath<RequestWithProcessId>(PosEndpoints.AllowedDocuments, params));
    return data;
  },
  updateOCRData: async (params: RequestWithIdentId, body: PosOCRDataPayload): Promise<PosOCRDataResponse> => {
    const { data } = await request.put(generatePath<RequestWithIdentId>(PosEndpoints.OCRData, params), body);
    return data;
  },
  retrieveOCRData: async (params: RequestWithIdentId): Promise<PosOCRDataResponse> => {
    const { data } = await request.get(generatePath<RequestWithIdentId>(PosEndpoints.OCRData, params));
    return data;
  },
  retrieveSignServiceProcessId: async (
    params: SignServiceProcessIdPathParams,
  ): Promise<PosSignServiceProcessIdResponse> => {
    const { data } = await request.get(
      generatePath<SignServiceProcessIdPathParams>(PosEndpoints.SignServiceProcessId, params),
    );
    return data;
  },
  retrieveRedirectUrl: async (params: QueryParamsWithProcessAndSubProcessId): Promise<PosRedirectUrlResponse> => {
    const { data } = await request.get(
      generatePath<QueryParamsWithProcessAndSubProcessId>(PosEndpoints.RedirectUrl, params),
    );
    return data;
  },
};
