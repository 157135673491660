import { ReactElement, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

import { SubProcessType } from '@/constants/common';
import { ErrorCode, PROCESS_TERMINATION_ERROR_CODES, RESOURCE_ID_NOT_FOUND } from '@/constants/error-codes';

import { UseErrorDialog, useErrorDialog } from '@/hooks/useErrorDialog';

import { commonFetchQueries } from '@/queries/common/fetch';
import { identFetchQueries } from '@/queries/ident/fetch';
import { verifyFetchQueries } from '@/queries/verify/fetch';

import { ExtendedSubProcess } from '@/types/interfaces';
import { ErrorResponse } from '@/types/responses';

import { ApiResponse } from '@/utils/api';
import { isWebview } from '@/utils/environment';
import { iOsProcessCancelHandler } from '@/utils/iosHandlers';

import { ErrorDialogInfo, getErrorInfo, subProcessErrorMap } from './useReactQuery.utils';

type HandleErrorDialogProps = {
  titleKey: string;
  subtitleKey: string;
  buttonCloseLabelKey: string;
  Icon?: ReactElement;
  onClose: () => void;
  serviceProviderName?: string;
};

export const useReactQuery = (): { queryClient: QueryClient; globalError: UseErrorDialog } => {
  const router = useRouter();
  // eslint-disable-next-line prefer-const
  let queryClient: QueryClient;
  const globalError = useErrorDialog();
  const { t } = useTranslation('errors');
  const processId = router.query.processId as string;

  const onSuccess = useCallback((data: unknown) => {
    if (!data) {
      return;
    }
    const dataResponse = data as ApiResponse<unknown>;
    const { successMessage } = dataResponse;
    if (successMessage) {
      console.log(successMessage);
    }
  }, []);

  const getServiceProviderResponse = useCallback(
    async ({ subProcessId, subProcessType }: { subProcessId: string; subProcessType: SubProcessType }) => {
      const { serviceProviderId } = await commonFetchQueries.useServiceProviderId({ processId }, queryClient);
      const { name: serviceProviderName } = await commonFetchQueries.useServiceProviderData(
        { serviceProviderId },
        queryClient,
      );

      const fetchRedirectUrlQuery =
        subProcessType === SubProcessType.Ident
          ? identFetchQueries.useFetchRetrieveRedirectUrl
          : verifyFetchQueries.useFetchRetrieveRedirectUrl;

      const { redirectUrl } = await fetchRedirectUrlQuery({ processId, subProcessId }, queryClient);

      return { serviceProviderName, redirectUrl };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [processId],
  );

  const handleErrorDialog = useCallback(
    ({ titleKey, subtitleKey, buttonCloseLabelKey, Icon, onClose, serviceProviderName }: HandleErrorDialogProps) => {
      globalError.setErrorDialog(
        true,
        {
          icon: Icon,
          title: t<string>(titleKey),
          subtitle: t<string>(subtitleKey, { serviceProviderName }),
          buttonClose: t<string>(buttonCloseLabelKey),
          onClose,
        },
        true,
      );
    },
    [globalError, t],
  );

  const processIdNotFoundErrorHandler = useCallback(() => {
    handleErrorDialog({
      titleKey: 'dialogTitles.ProcessIdNotFound',
      subtitleKey: isWebview()
        ? 'dialogMessages.ProcessIdNotFound.callbackUrl'
        : 'dialogMessages.ProcessIdNotFound.redirectUrl',
      buttonCloseLabelKey: isWebview()
        ? 'dialogSubmitButton.ProcessIdNotFound.callbackUrl'
        : 'dialogSubmitButton.ProcessIdNotFound.redirectUrl',
      onClose: () => {
        if (isWebview()) {
          iOsProcessCancelHandler();
        } else {
          if (router.pathname !== '/') {
            void router.push('/');
          }
        }
      },
    });
  }, [handleErrorDialog, router]);

  const showErrorWithServiceProviderName = useCallback(
    ({
      process,
      errorInfo,
      redirectUrl,
      serviceProviderName,
    }: {
      process: ExtendedSubProcess;
      errorInfo: ErrorDialogInfo;
      redirectUrl: string;
      serviceProviderName: string;
    }) => {
      handleErrorDialog({
        titleKey: errorInfo.title,
        subtitleKey: errorInfo.messages.redirectUrl as string,
        buttonCloseLabelKey: errorInfo.buttonCloseLabelKey?.redirectUrl ?? 'dialogSubmitButton.timeExpired.redirectUrl',
        Icon: errorInfo.Icon,
        onClose: () => router.push({ pathname: redirectUrl, query: { processId, status: process?.status } }),
        serviceProviderName,
      });
    },
    [handleErrorDialog, processId, router],
  );

  const showError = useCallback(
    ({ errorInfo }: { errorInfo: ErrorDialogInfo }) => {
      handleErrorDialog({
        titleKey: errorInfo.title,
        subtitleKey: (isWebview() ? errorInfo.messages.qrCodeScanner : errorInfo.messages.callbackUrl) as string,
        buttonCloseLabelKey: isWebview()
          ? errorInfo.buttonCloseLabelKey?.qrCodeScanner ?? 'dialogSubmitButton.timeExpired.qrScanner'
          : errorInfo.buttonCloseLabelKey?.callbackUrl ?? 'dialogSubmitButton.timeExpired.callbackUrl',
        Icon: errorInfo.Icon,
        onClose: () => {
          if (isWebview()) {
            iOsProcessCancelHandler();
          } else {
            if (router.pathname !== '/') {
              router.push({ pathname: '/' });
            }
          }
        },
      });
    },
    [handleErrorDialog, router],
  );

  const processTerminationErrorHandler = useCallback(
    async (errorCode: ErrorCode) => {
      const statuses = await commonFetchQueries.useFetchStatuses({ processId }, queryClient);
      const process: ExtendedSubProcess | undefined = subProcessErrorMap[errorCode]?.(statuses);
      const errorInfo: ErrorDialogInfo = getErrorInfo(errorCode);

      try {
        if (!process?.id) {
          throw new Error('Process is not found');
        }
        const { serviceProviderName, redirectUrl } = await getServiceProviderResponse({
          subProcessId: process.id,
          subProcessType: process.type,
        });

        showErrorWithServiceProviderName({ process, errorInfo, redirectUrl, serviceProviderName });
      } catch (e) {
        showError({ errorInfo });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getServiceProviderResponse, handleErrorDialog, processId, router],
  );

  const onError = useCallback(
    async (error: unknown) => {
      const errorResponse = error as ErrorResponse;

      if (errorResponse?.code) {
        if (PROCESS_TERMINATION_ERROR_CODES.includes(errorResponse?.code)) {
          await processTerminationErrorHandler(errorResponse.code);
        } else if (RESOURCE_ID_NOT_FOUND.includes(errorResponse.code)) {
          processIdNotFoundErrorHandler();
        }
      }
      const message = errorResponse?.title || 'Something went wrong';
      if (message) {
        console.error('onError', message);
      }
    },
    [processIdNotFoundErrorHandler, processTerminationErrorHandler],
  );

  const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

  const [qC] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: twentyFourHoursInMs,
            cacheTime: twentyFourHoursInMs,
            refetchInterval: twentyFourHoursInMs,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: false,
            retryOnMount: false,
            retry: false,
          },
          mutations: {
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError,
          onSuccess,
        }),
        mutationCache: new MutationCache({
          onError,
          onSuccess,
        }),
      }),
  );

  useEffect(() => {
    qC.getQueryCache().config.onError = onError;
    qC.getMutationCache().config.onError = onError;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  queryClient = qC;

  return { queryClient, globalError };
};
