import { ReactElement } from 'react';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

import { IdentFailureStatuses, SignFailureStatuses, SubProcessType, VerifyFailureStatuses } from '@/constants/common';
import { ErrorCode } from '@/constants/error-codes';

import { ExtendedSubProcess } from '@/types/interfaces';
import { PosStatusesResponse } from '@/types/responses';

export type ErrorDialogInfo = {
  title: string;
  Icon: ReactElement;
  messages: Partial<Record<'redirectUrl' | 'qrCodeScanner' | 'callbackUrl', string>>;
  buttonCloseLabelKey?: Partial<Record<'redirectUrl' | 'qrCodeScanner' | 'callbackUrl', string>>;
};

const apiErrorsMap: Partial<Record<ErrorCode, ErrorDialogInfo>> = {
  [ErrorCode.IdentAborted]: {
    title: 'dialogTitles.processCanceled',
    Icon: <HighlightOffIcon fontSize={'large'} />,
    messages: {
      redirectUrl: 'dialogMessages.processCanceled.redirectUrl',
      callbackUrl: 'dialogMessages.processCanceled.callbackUrl',
      qrCodeScanner: 'dialogMessages.processCanceled.qrScanner',
    },
  },
  [ErrorCode.IdentFailed]: {
    title: 'dialogTitles.processFailed',
    Icon: <HighlightOffIcon fontSize={'large'} />,
    messages: {
      redirectUrl: 'dialogMessages.processFailed.redirectUrl',
      callbackUrl: 'dialogMessages.processFailed.callbackUrl',
      qrCodeScanner: 'dialogMessages.processFailed.qrScanner',
    },
  },
  [ErrorCode.IdentExpired]: {
    title: 'dialogTitles.timeExpired',
    Icon: <HourglassBottomIcon fontSize={'large'} />,
    messages: {
      redirectUrl: 'dialogMessages.timeExpired.redirectUrl',
      callbackUrl: 'dialogMessages.timeExpired.callbackUrl',
      qrCodeScanner: 'dialogMessages.processFailed.qrScanner',
    },
  },
  [ErrorCode.IdentCanceled]: {
    title: 'dialogTitles.processCanceled',
    Icon: <HighlightOffIcon fontSize={'large'} />,
    messages: {
      redirectUrl: 'dialogMessages.processCanceled.redirectUrl',
      callbackUrl: 'dialogMessages.processCanceled.callbackUrl',
      qrCodeScanner: 'dialogMessages.processCanceled.qrScanner',
    },
  },
  [ErrorCode.VerifyAborted]: {
    title: 'dialogTitles.processCanceled',
    Icon: <HighlightOffIcon fontSize={'large'} />,
    messages: {
      redirectUrl: 'dialogMessages.processCanceled.redirectUrl',
      callbackUrl: 'dialogMessages.processCanceled.qrScanner',
      qrCodeScanner: 'dialogMessages.processCanceled.qrScanner',
    },
    buttonCloseLabelKey: {
      redirectUrl: 'dialogSubmitButton.processCanceled.redirectUrl',
      callbackUrl: 'dialogSubmitButton.processCanceled.qrScanner',
      qrCodeScanner: 'dialogSubmitButton.processCanceled.qrScanner',
    },
  },
  [ErrorCode.VerifyCanceled]: {
    title: 'dialogTitles.processCanceled',
    Icon: <HighlightOffIcon fontSize={'large'} />,
    messages: {
      redirectUrl: 'dialogMessages.processCanceled.redirectUrl',
      callbackUrl: 'dialogMessages.processCanceled.qrScanner',
      qrCodeScanner: 'dialogMessages.processCanceled.qrScanner',
    },
    buttonCloseLabelKey: {
      redirectUrl: 'dialogSubmitButton.processCanceled.redirectUrl',
      callbackUrl: 'dialogSubmitButton.processCanceled.qrScanner',
      qrCodeScanner: 'dialogSubmitButton.processCanceled.qrScanner',
    },
  },
  [ErrorCode.VerifyExpired]: {
    title: 'dialogTitles.timeExpired',
    Icon: <HourglassBottomIcon fontSize={'large'} />,
    messages: {
      redirectUrl: 'dialogMessages.timeExpired.redirectUrl',
      callbackUrl: 'dialogMessages.timeExpired.callbackUrl',
      qrCodeScanner: 'dialogMessages.processFailed.qrScanner',
    },
  },
};

export const subProcessErrorFn: Partial<Record<SubProcessType, (statuses: PosStatusesResponse) => ExtendedSubProcess>> =
  {
    [SubProcessType.Ident]: (statuses: PosStatusesResponse) => ({
      type: SubProcessType.Ident,
      ...statuses.idents.find(({ status }) => IdentFailureStatuses.includes(status)),
    }),
    [SubProcessType.Sign]: (statuses: PosStatusesResponse): ExtendedSubProcess => ({
      type: SubProcessType.Sign,
      ...statuses.signs.find(({ status }) => SignFailureStatuses.includes(status)),
    }),
    [SubProcessType.Verify]: (statuses: PosStatusesResponse) => ({
      type: SubProcessType.Verify,
      ...statuses.verifications.find(({ status }) => VerifyFailureStatuses.includes(status)),
    }),
  };

export const subProcessErrorMap: Partial<Record<ErrorCode, (statuses: PosStatusesResponse) => ExtendedSubProcess>> = {
  [ErrorCode.IdentExpired]: subProcessErrorFn[SubProcessType.Ident],
  [ErrorCode.IdentCanceled]: subProcessErrorFn[SubProcessType.Ident],
  [ErrorCode.IdentAborted]: subProcessErrorFn[SubProcessType.Ident],
  [ErrorCode.IdentFailed]: subProcessErrorFn[SubProcessType.Ident],
  [ErrorCode.SignExpired]: subProcessErrorFn[SubProcessType.Sign],
  [ErrorCode.VerifyExpired]: subProcessErrorFn[SubProcessType.Verify],
  [ErrorCode.VerifyCanceled]: subProcessErrorFn[SubProcessType.Verify],
  [ErrorCode.VerifyAborted]: subProcessErrorFn[SubProcessType.Verify],
};

export function getErrorInfo(errorCode?: ErrorCode) {
  return (errorCode ? apiErrorsMap[errorCode] : apiErrorsMap[ErrorCode.IdentExpired]) as ErrorDialogInfo;
}
