import { createContext, Fragment, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { Modal, ModalOptions, ModalType } from '@/components/dialogs/Modal';

export type ModalContextType = {
  openModal: (newModalOptions: ModalOptions) => void;
  closeModal: () => void;
  confirmModal: (newModalOptions: ModalOptions) => Promise<unknown>;
};

export const ModalContext = createContext<ModalContextType>({
  openModal: () => {
    console.warn('Modal provider not found.');
  },
  closeModal: () => {},
  confirmModal: () => {
    console.warn('Modal provider not found.');
    return Promise.resolve();
  },
});

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [options, setOptions] = useState<ModalOptions>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resolveReject, setResolveReject] = useState<[(value?: unknown) => void, (reason?: unknown) => void] | []>([]);
  const [key, setKey] = useState(0);
  const [isConfirm, setIsConfirm] = useState(false);
  const [resolvePromise, rejectPromise] = resolveReject;

  const openModal = useCallback((modalOptions: ModalOptions) => {
    setKey(prevKey => prevKey + 1);
    setOptions(modalOptions);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setResolveReject([]);
    setIsModalOpen(false);
  }, []);

  const handleConfirmModal = useCallback(
    (newOptions: ModalOptions) => {
      return new Promise((resolve, reject) => {
        setIsConfirm(true);
        setResolveReject([resolve, reject]);
        openModal(newOptions);
      });
    },
    [openModal],
  );

  const handleOpenModal = useCallback(
    (modalOptions: ModalOptions) => {
      setIsConfirm(false);
      openModal(modalOptions);
    },
    [openModal],
  );

  const onCancelClose = useCallback(() => {
    rejectPromise?.();
    closeModal();
  }, [rejectPromise, closeModal]);

  const onConfirm = useCallback(() => {
    resolvePromise?.();
    closeModal();
  }, [resolvePromise, closeModal]);

  const providerValue = useMemo(
    () => ({
      openModal: handleOpenModal,
      closeModal,
      confirmModal: handleConfirmModal,
    }),
    [closeModal, handleConfirmModal, handleOpenModal],
  );

  return (
    <Fragment>
      <ModalContext.Provider value={providerValue}>{children}</ModalContext.Provider>
      <Modal
        key={key}
        options={options}
        open={isModalOpen}
        onClose={onCancelClose}
        onConfirm={onConfirm}
        modalType={isConfirm ? ModalType.Confirmation : ModalType.Informational}
      />
    </Fragment>
  );
};
