export function isBrowser(): boolean {
  return typeof window !== 'undefined';
}

export function isDev(): boolean {
  return process.env.NODE_ENV === 'development';
}

export function isMockEnabled(): boolean {
  return process.env.NEXT_PUBLIC_API_MOCKING === 'true';
}

export function isWaas(): boolean {
  return process.env.VERIMI_IS_WAAS === 'true';
}

export function isDebug(): boolean {
  return process.env.DEBUG === 'true';
}

export const isWebview = (): boolean => {
  if (!isBrowser() || !navigator?.userAgent) {
    return false;
  }

  const rules = [
    // if it says it's a webview, let's go with that
    'WebView',
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad)(?!.*Safari)',
    // Android Lollipop and Above: webview will be the same as native, but it will contain "wv"
    // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
    'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
    // old chrome android webview agent
    'Linux; U; Android',
  ];

  const webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig');

  //Detect webview from user agent string or window.webkit which is only available on ios webview
  return webviewRegExp.test(navigator.userAgent) || !!window.webkit;
};
