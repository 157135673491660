import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { ErrorCode } from '@/constants/error-codes';

import { ErrorResponse } from '@/types/responses';

class ApiError implements ErrorResponse {
  id?: string;

  code?: ErrorCode;

  timestamp?: string;

  title?: string;

  detail?: string;

  validation?: { [key: string]: string };

  status?: number;

  message?: string;

  constructor(data: ErrorResponse) {
    Object.assign(this, data);
    if (data.timestamp === undefined) {
      this.timestamp = new Date().toISOString();
    }
  }
}

const axiosInstance: AxiosInstance = axios.create({
  headers: {
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Origin': '*',
    'Verimi-Business-Client-Platform': 'web',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    // Add your authentication logic here, e.g. adding tokens to headers
    // config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  async (error: AxiosError<{ error?: ErrorResponse }>): Promise<ErrorResponse> => {
    const { response, request } = error;

    if (response) {
      const { data, status } = response;
      switch (status) {
        case 500:
          console.error('An error occurred on the server.');
          break;
        default:
          break;
      }

      if (data && typeof data === 'object' && 'error' in data && data.error) {
        return Promise.reject(
          new ApiError({
            id: data.error.id,
            code: data.error.code,
            timestamp: data.error.timestamp,
            title: data.error.title,
            detail: data.error.detail,
            validation: data.error.validation,
            status,
          }),
        );
      }

      const errorDetails = error.toJSON() as AxiosError<string>;
      return Promise.reject(
        new ApiError({
          id: errorDetails.name,
          code: errorDetails.code as ErrorCode,
          title: errorDetails.message,
          detail: errorDetails.stack,
          status,
        }),
      );
    }

    if (request) {
      const { status } = request;
      return Promise.reject(
        new ApiError({
          code: status,
          message: `Request failed with ${status}`,
        }),
      );
    }

    return Promise.reject(
      new ApiError({
        id: error.name,
        code: error.code as ErrorCode,
        title: error.message,
        detail: error.stack,
      }),
    );
  },
);

export { axiosInstance as request, ApiError };
