import { QueryClient } from '@tanstack/react-query';

import { identApi } from '@/api/ident';

import { QUERIES } from '@/queries/queries';

export const identFetchQueries = {
  useFetchRetrieveRedirectUrl: async (
    params: { processId: string; subProcessId: string },
    queryClient: QueryClient,
  ) => {
    const request = await queryClient.fetchQuery({
      queryKey: [QUERIES.REDIRECT_URL_RETRIEVE, params],
      queryFn: () => identApi.retrieveRedirectUrl(params),
    });
    return request;
  },
  useFetchRetrieveSignServiceProcessId: async (
    params: { signId: string; processId: string },
    queryClient: QueryClient,
  ) => {
    const request = await queryClient.fetchQuery({
      queryKey: [QUERIES.SIGNIN_SERVICE_PROCESS_ID_RETRIEVE, params],
      queryFn: () => identApi.retrieveSignServiceProcessId(params),
    });
    return request;
  },
};
