export enum ErrorCode {
  IdentExpired = 'LIQUID_130_IDENT_EXPIRED',
  IdentAborted = 'LIQUID_140_IDENT_ABORTED',
  IdentFailed = 'LIQUID_141_IDENT_FAILED',
  IdentCanceled = 'LIQUID_142_IDENT_CANCELED',
  VerifyExpired = 'LIQUID_131_VERIFY_EXPIRED',
  VerifyAborted = 'LIQUID_143_VERIFY_ABORTED',
  VerifyCanceled = 'LIQUID_144_VERIFY_CANCELED',
  SignExpired = 'LIQUID_132_SIGN_EXPIRED',
  ProcessIdNotFound = 'LIQUID_004_PROCESS_ID_NOT_FOUND',
  IdentIdNotFound = 'LIQUID_115_IDENT_ID_NOT_FOUND',
  BarmerIdentityVerificationFailed = 'LIQUID_135_BARMER_IDENTITY_VERIFICATION_FAILED',
  BarmerIdentityVerificationTechError = 'LIQUID_139_BARMER_IDENTITY_VERIFICATION_TECHNICAL_ERROR',
  PhoneNumberIsNotValid = 'LIQUID_120_PHONE_NUMBER_IS_NOT_MOBILE',
}

export const RESOURCE_ID_NOT_FOUND = [ErrorCode.ProcessIdNotFound, ErrorCode.IdentIdNotFound];
export const PROCESS_TERMINATION_ERROR_CODES = [
  ErrorCode.IdentExpired,
  ErrorCode.SignExpired,
  ErrorCode.VerifyExpired,
  ErrorCode.VerifyAborted,
  ErrorCode.VerifyCanceled,
  ErrorCode.IdentCanceled,
  ErrorCode.IdentAborted,
  ErrorCode.IdentFailed,
];

export enum ErrorReason {
  AuthFailed = 'authenticationFailed',
  Timeout = 'timeout',
  UserBlocked = 'userBlocked',
  Canceled = 'canceled',
  GenericError = 'genericError',
}

const CANCELED_ERROR_CODES: string[] = [
  'user_canceled_login',
  'user_canceled_consent',
  'user_canceled_nfa',
  'user_canceled_ident',
  'user_canceled_verimi-bank-ident',
];

export const getErrorCode = (errorReason: string): ErrorReason => {
  if (CANCELED_ERROR_CODES.includes(errorReason)) {
    return ErrorReason.Canceled;
  }

  if (errorReason === 'user_run_into_timeout') {
    return ErrorReason.Timeout;
  }

  if (errorReason === 'user_blocked') {
    return ErrorReason.UserBlocked;
  }

  return ErrorReason.GenericError;
};
