import { ElementType, MutableRefObject, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';

import { CssBaseline, ThemeProvider } from '@mui/material';

import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider, Global } from '@emotion/react';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ErrorFallbackPage } from '@/components/error-pages/GenericError';
import { GlobalErrorProvider } from '@/components/providers/GlobalErrorProvider';
import { InstructionOverlayProvider } from '@/components/providers/InstructionOverlayProvider';
import { ModalProvider } from '@/components/providers/ModalProvider';

import { useNavigationProgress } from '@/hooks/useNavigationProgress';
import { useReactQuery } from '@/hooks/useReactQuery/useReactQuery';

import { setupMocks } from '@/mocks/index';

import { globalStyles } from '@/styles/globals';

import { theme } from '@/themes/theme';

import { isMockEnabled } from '@/utils/environment';

if (isMockEnabled()) {
  await setupMocks();
}

type ComponentWithPageLayout = AppProps & {
  Component: AppProps['Component'] & {
    PageLayout?: ElementType;
  };
};

function App({ Component, pageProps }: ComponentWithPageLayout) {
  const { queryClient, globalError } = useReactQuery();
  useNavigationProgress();

  // style cache is shared per browser session
  // nonce is set by the initial pre-render on ssr and then reused on csr
  const emotionCache: MutableRefObject<EmotionCache> = useRef(
    createCache({
      key: 'verimi',
      nonce: pageProps.nonce || '',
    }),
  );

  return (
    <CacheProvider value={emotionCache.current}>
      <Global styles={globalStyles} />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
              <InstructionOverlayProvider>
                <ModalProvider>
                  <GlobalErrorProvider
                    isError={globalError.isError}
                    errorDialogParams={globalError.errorDialogParams}
                    hideChildren={globalError.hideChildren}>
                    {Component.PageLayout ? (
                      <Component.PageLayout>
                        <Component {...pageProps} />
                      </Component.PageLayout>
                    ) : (
                      <Component {...pageProps} />
                    )}
                  </GlobalErrorProvider>
                </ModalProvider>
              </InstructionOverlayProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </CacheProvider>
  );
}

export default appWithTranslation(App);
