import { AxiosResponse } from 'axios';

import { request } from '@/api/axios';
import { MasterDataEndpoints, PosEndpoints } from '@/api/endpoints';

import { DocumentVerificationType, ProvidedDocumentFormat } from '@/constants/common';

import { CompanyAddressData, CompanyAutoCompleteOption, LegalEntityRepresentativesRequest } from '@/types/interfaces';
import { DocumentsRequestPayload } from '@/types/requests';
import {
  CompanyDataResponse,
  LegalEntityAutosuggestionResponse,
  LegalEntityRepresentativeDataResponse,
  PosRedirectUrlResponse,
} from '@/types/responses';
import { QueryParamsWithProcessAndSubProcessId, RequestWithProcessIdAndSubProcessId } from '@/types/types';

import { generatePath } from '@/utils/generatePath';

export type AutoSuggestionSearchParams = {
  query: string;
  limit: string;
};

export type DocumentVerificationPayload = {
  data: string;
  numberOfPages: number;
  type: DocumentVerificationType;
  format: ProvidedDocumentFormat;
};

export type CompanyDataParams = {
  companyId: string;
};

export type UploadedDocumentsDataParams = {
  processId: string;
  subProcessId: string;
  documentId: string;
};

export const verifyApi = {
  autosuggestions: async (
    params: AutoSuggestionSearchParams,
  ): Promise<(LegalEntityAutosuggestionResponse[number] & CompanyAutoCompleteOption)[]> => {
    const { data } = await request.get(generatePath<AutoSuggestionSearchParams>(MasterDataEndpoints.LegalEntities), {
      params,
    });

    return (
      data
        ?.map?.(
          (item: {
            address: { city: string; country: string };
            name: { name: string };
            register: { id: number };
            id: string;
          }) => {
            const tagCity = item?.address?.city ?? '';
            const tag =
              tagCity +
              (item?.address?.country && tagCity ? `, ${item?.address?.country}` : item?.address?.country ?? '');
            return {
              ...item,
              label: item?.name?.name ?? '',
              subtitle: item?.register?.id ?? '',
              tag,
              value: item?.id ? parseFloat(item?.id) : 0,
            };
          },
        )
        ?.filter((item: { value: unknown }) => item?.value) ?? []
    );
  },
  representativeData: async (
    params: RequestWithProcessIdAndSubProcessId,
  ): Promise<LegalEntityRepresentativeDataResponse> => {
    const { data } = await request.get(
      generatePath<RequestWithProcessIdAndSubProcessId>(PosEndpoints.LegalEntityRepresentativeData, params),
    );
    return data;
  },
  selectRepresentative: async (
    params: RequestWithProcessIdAndSubProcessId,
    body: LegalEntityRepresentativesRequest,
  ): Promise<{ success: boolean }> => {
    const { data } = await request.post(
      generatePath<RequestWithProcessIdAndSubProcessId>(PosEndpoints.LegalEntitySelectRepresentativeAndPoA, params),
      body,
    );
    return data;
  },
  documentVerification: async (
    params: RequestWithProcessIdAndSubProcessId,
    body: DocumentVerificationPayload,
  ): Promise<unknown> => {
    const { data } = await request.post(
      generatePath<RequestWithProcessIdAndSubProcessId>(PosEndpoints.LegalEntityDocumentVerification, params),
      body,
    );
    return data;
  },
  updateLegalEntityAddress: async (
    params: RequestWithProcessIdAndSubProcessId,
    body: Omit<CompanyAddressData, 'country'> & { northDataId: string },
  ): Promise<unknown> => {
    const { data } = await request.post(
      generatePath<RequestWithProcessIdAndSubProcessId>(PosEndpoints.LegalEntityAddress, params),
      body,
    );
    return data;
  },
  undoProcessStep: async (params: RequestWithProcessIdAndSubProcessId): Promise<AxiosResponse> => {
    return request.post(generatePath<RequestWithProcessIdAndSubProcessId>(PosEndpoints.UndoStep, params));
  },
  getCompanyData: async (params: CompanyDataParams): Promise<CompanyDataResponse> => {
    const { data } = await request.get(generatePath<CompanyDataParams>(MasterDataEndpoints.CompanyData, params));
    return data;
  },
  cancelProcess: async (params: QueryParamsWithProcessAndSubProcessId): Promise<AxiosResponse> => {
    return request.post(generatePath<QueryParamsWithProcessAndSubProcessId>(PosEndpoints.VerifyCancel, params));
  },
  retrieveRedirectUrl: async (params: QueryParamsWithProcessAndSubProcessId): Promise<PosRedirectUrlResponse> => {
    const { data } = await request.get(
      generatePath<QueryParamsWithProcessAndSubProcessId>(PosEndpoints.VerifyRedirectUrl, params),
    );
    return data;
  },
  getUploadedDocumentsData: async (params: QueryParamsWithProcessAndSubProcessId): Promise<unknown> => {
    const { data } = await request.get(
      generatePath<QueryParamsWithProcessAndSubProcessId>(PosEndpoints.VerifyGetUploadedDocumentsData, params),
    );
    return data;
  },
  uploadDocument: (params: UploadedDocumentsDataParams, body: DocumentsRequestPayload) => {
    return request.put<DocumentsRequestPayload>(
      generatePath<UploadedDocumentsDataParams>(PosEndpoints.VerifyUploadDocumentData, params),
      body,
    );
  },
  deleteDocument: (params: UploadedDocumentsDataParams) => {
    return request.delete<DocumentsRequestPayload>(
      generatePath<UploadedDocumentsDataParams>(PosEndpoints.VerifyUploadDocumentData, params),
    );
  },
  finishDocumentVerification: (params: QueryParamsWithProcessAndSubProcessId) => {
    return request.post(
      generatePath<QueryParamsWithProcessAndSubProcessId>(PosEndpoints.VerifyFinishDocumentVerification, params),
    );
  },
};
