import { Roboto } from 'next/font/google';

import { css } from '@emotion/react';

const roboto = Roboto({ weight: ['400', '500'], subsets: ['latin'] });

export const globalStyles = css`
  html,
  body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-family: ${roboto.style.fontFamily};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h1,
  h2,
  p,
  ul {
    margin: 0;
  }

  button {
    padding: 0.5rem 1rem;
    font-weight: bold;
  }

  #hyperLink {
    text-decoration: underline;
    color: #36b37e;
  }

  blinkid-in-browser {
    width: 0;
  }

  blinkid-in-browser::part(mb-component) {
    visibility: hidden;
  }

  blinkid-in-browser::part(mb-screen-action) {
    display: none !important;
  }

  blinkid-in-browser::part(mb-screen-error) {
    display: none !important;
  }

  blinkid-in-browser::part(mb-screen-loading) {
    display: none !important;
  }

  blinkid-in-browser::part(mb-feedback) {
    display: none !important;
  }

  div.MuiModal-root:has(> div[style*='opacity: 0']) {
    pointer-events: none;
  }

  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: #006b5d;

    position: fixed;
    z-index: 1500;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow:
      0 0 10px #006b5d,
      0 0 5px #006b5d;
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }

  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: #006b5d;
    border-left-color: #006b5d;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @-webkit-keyframes nprogress-spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
