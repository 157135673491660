import { createContext, PropsWithChildren, useCallback, useRef, useState } from 'react';

type InstructionOverlayProviderType = {
  isInstructionOverlayOpen: boolean;
  setIsInstructionOverlayOpen: (value: boolean, key?: string) => void;
};

export const InstructionOverlayContext = createContext<InstructionOverlayProviderType>({
  isInstructionOverlayOpen: false,
  setIsInstructionOverlayOpen: () => {},
});

export const InstructionOverlayProvider = ({ children }: PropsWithChildren) => {
  const [isInstructionOverlayOpen, setIsInstructionOverlayOpen] = useState(false);
  const overlayKey = useRef<string>();

  const handleOverlayState: InstructionOverlayProviderType['setIsInstructionOverlayOpen'] = useCallback(
    (value, key) => {
      if (overlayKey.current !== key) {
        setIsInstructionOverlayOpen(value);

        if (key) {
          overlayKey.current = key;
        }
      }
    },
    [],
  );

  return (
    <InstructionOverlayContext.Provider
      value={{ isInstructionOverlayOpen, setIsInstructionOverlayOpen: handleOverlayState }}>
      {children}
    </InstructionOverlayContext.Provider>
  );
};
